<template>
  <div class="auth-wrapper auth-v2">

    <template v-if="$device.isMobile">

      <template v-if="scanner">
        <Scanner/>
        <div class="closeScannerContainer">
          <p class="header-bottom pb-2">Սքանավորեք հաշվառման վկայագիրը կամ տեխզննման արատորոշման քարտը</p>
          <b-button
              type="submit"
              variant="success"
              block
              size="sm"
              @click="scanner = false"
          >
            <feather-icon
                icon="XCircleIcon"
                class="mr-50"
            />
            Փակել
          </b-button>
        </div>
      </template>

      <template v-else>
        <div class="mobile-header">
          <Menus />
          <div v-if="selectedLayout==='search'" @click="selectedLayout = 'login'" class="login">
            <fa-icon icon="sign-in-alt"/>
            <span>Մուտք</span>
          </div>
          <div v-else @click="selectedLayout = 'search'" class="login">
            <fa-icon icon="search"/>
            <span>Որոնել</span>
          </div>
        </div>
        <b-row v-if="selectedLayout === 'search'" class="auth-inner m-0">
          <b-col
              lg="3"
              class="d-flex mobileSearchLayout align-items-center auth-bg px-0 p-lg-5"
          >
            <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
            >
              <b-card-title title-tag="h3" class="font-weight-bold mb-1 text-center pb-1 login-header-title">
                Տեխզննման ժամկետի ստուգում
<!--                <b-button-->
<!--                    class="tooltipButton"-->
<!--                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"-->
<!--                    v-b-tooltip.hover.v-dark-->
<!--                    title="Տեղեկատվությունը ստուգվում է վերջին երեք տարվա ժամանակահատվածի համար։"-->
<!--                    variant="outline-dark">-->
<!--                  <fa-icon icon="info-circle"/>-->
<!--                </b-button>-->
              </b-card-title>

              <!-- form -->
              <validation-observer #default="{invalid}" v-if="!verificationPage" ref="searchValidation">
                <b-form
                    autocomplete="off"
                    class="auth-login-form mt-2"
                    @submit.prevent
                >

                  <!-- document Number -->
                  <validation-provider
                      #default="{ errors }"
                      name="CarRegNo"
                      rules="required"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge inputGroup">
                        <input
                            v-model="CarRegNo"
                            class="text-uppercase form-control"
                            autocomplete="off"
                            id="CarRegNo2"
                            name="car-reg-no"
                            placeholder="Հաշվառման համարանիշը"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                      #default="{ errors }"
                      name="documentNumber"
                      rules="required|min:8"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge inputGroup">
<!--                        <div @click="openSearchTypeModal" class="left-icon">-->
<!--                          <fa-icon icon="undo" />-->
<!--                        </div>-->
                        <input
                            v-model="documentNumber"
                            class="text-uppercase form-control"
                            autocomplete="off"
                            id="document_number"
                            name="document-number"
                            :placeholder="search_type_title"
                            v-mask="searchMask"
                        />
<!--                        <div @click="openScanner" class="inputScanner">-->
<!--                          <b-img-->
<!--                              fluid-->
<!--                              class="scanner-image"-->
<!--                              :src="qrUrl"-->
<!--                              alt="Login V2"-->
<!--                          />-->
<!--                        </div>-->
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                  <!-- submit buttons -->

                  <div class="w-100 mb-2 mobile-recaptcha">
                    <vue-recaptcha ref="recaptcha" sitekey="6LfeVXsfAAAAAP7sjbSql3RhvxicKaPn2d7LnvdE" language="hy" @verify="verifyRecaptchaMobile($event)" @error="mobileRecaptchaStatus = false" />
                  </div>

                  <b-button
                      :disabled="invalid || !mobileRecaptchaStatus"
                      type="submit"
                      variant="success"
                      block
                      @click="checkTechService"
                  >
                    Որոնել
                  </b-button>
                </b-form>
              </validation-observer>
              <div id="searchResult" v-if="searchResult">
                <div class="res">
                  <div class="column">
                    <div class="label">Մակնիշ</div>
                    <div class="value">{{ searchResult.car }}</div>
                  </div>
                  <div class="column">
                    <div class="label">Ժամկետի ավարտ</div>
                    <div class="value">
                      <template v-if="check_is_expired(searchResult.lastDay)">
                        {{ searchResult.ValidDate }}
<!--                        <b-button-->
<!--                            style="left: 10px; top: 0"-->
<!--                            class="tooltipButton2"-->
<!--                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"-->
<!--                            v-b-tooltip.hover.v-dark-->
<!--                            title="Տրանսպորտային միջոցը տեխնիկական զննության պետք է ներկայացվի մինչև նախորդ տեխնիկական զննությամբ տրամադրված արատորոշման քարտում նշված ամսվա վերջին օրը:"-->
<!--                            variant="outline-dark">-->
<!--                          <fa-icon icon="info-circle"/>-->
<!--                        </b-button>-->
                      </template>

                      <p class="isExpired animate-flicker" v-if="!check_is_expired(searchResult.lastDay)">Ժամկետանց</p>
                    </div>
                  </div>
                  <div v-if="paymentStatus && check_is_expired(searchResult.lastDay)" class="column">
                    <div class="label">Ժամկետի ավարտ</div>
                    <div class="value">
                      <b-button @click="$bvModal.show('payment-modal')" style="height: 37px; font-size: 13px; background: rgb(22, 97, 54) !important; border-color: rgb(22, 97, 54) !important; padding: 0 12px;" variant="success">
                        <fa-icon style="    margin-right: 5px;" icon="bell"/>
                        SMS ծանուցում
                      </b-button>
                    </div>
                  </div>
                  <div class="column">
                    <div class="label">Վճարումներ</div>
                    <div class="value">
<!--                      <fa-icon @click="openAutoZone" icon="envelope" class="envelopeIcon" />-->
                      <b-button style="width: 65%" @click="openOnePayPayments" variant="success" class="pay-ts">
                        <fa-icon icon="info-circle"/>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="searchErrorMessage" v-if="searchMessage !== ''">
                <span>{{ searchMessage }}</span>
<!--                <b-button-->
<!--                    class="tooltipButton2"-->
<!--                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"-->
<!--                    v-b-tooltip.hover.v-dark-->
<!--                    title="Արդյունքների բացակայության դեպքում առաջարկում ենք որոնել VIN համարով"-->
<!--                    variant="outline-dark">-->
<!--                  <fa-icon class="searchInfoIcon animate-flicker" icon="info-circle"/>-->
<!--                </b-button>-->
              </div>
<!--              <div id="informMessage" v-if="searchMessage !== ''">-->
<!--                <b-button-->
<!--                    type="submit"-->
<!--                    variant="success"-->
<!--                    block-->
<!--                    @click="openOnePay"-->
<!--                >-->
<!--                  Առցանց վճարումներ-->
<!--                </b-button>-->
<!--              </div>-->
            </b-col>
          </b-col>
        </b-row>
        <b-row v-else-if="selectedLayout === 'login'" class="auth-inner m-0">
          <b-col
              lg="3"
              class="d-flex align-items-center auth-bg p-lg-5"
          >
            <b-col
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
            >
              <b-card-title title-tag="h3" class="font-weight-bold mb-1 text-center pb-1 login-header-title">Մուտք
                համակարգ
              </b-card-title>

              <!-- form -->
              <validation-observer v-if="!verificationPage" ref="loginValidation">
                <b-form
                    autocomplete="off"
                    class="auth-login-form mt-2"
                    @submit.prevent
                >
                  <!-- email -->
                  <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            @focus="showGov = false"
                            @blur="showGov = true"
                            autocomplete="off"
                            id="login-email-1"
                            v-model="userEmail"
                            name="login-email"
                            placeholder="Էլ․ փոստ"
                            :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            @focus="showGov = false"
                            @blur="showGov = true"
                            autocomplete="off"
                            id="login-password-1"
                            v-model="password"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder="Գաղտնաբառ"
                            :state="errors.length > 0 ? false:null"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>


                  <!-- submit buttons -->
                  <b-button
                      type="submit"
                      variant="success"
                      block
                      @click="validationForm"
                  >
                    Մուտք
                  </b-button>
                </b-form>
              </validation-observer>

              <template v-else>
                <validation-observer ref="verificationValidation">
                  <b-form
                      autocomplete="off"
                      class="auth-login-form mt-2"
                      @submit.prevent
                  >
                    <p>{{ verificationMessage }}</p>

                    <!-- email -->
                    <validation-provider #default="{ errors }" rules="required|min:6">

                      <MazInput
                          type="text"
                          name="email"
                          v-model="verificationCode"
                          placeholder="Հաստատման կոդ"
                          autocomplete="new-email"
                          class="w-full mb-1"
                          v-mask="'######'"
                          old-left-icon-name="format_list_numbered"
                      />
                    </validation-provider>

                    <!-- submit buttons -->
                    <b-button
                        type="submit"
                        variant="success"
                        block
                        :disabled="checkVerifyValidation"
                        @click="verify"
                    >
                      Հաստատել
                    </b-button>
                  </b-form>
                </validation-observer>
              </template>

            </b-col>
          </b-col>
        </b-row>
        <b-row v-else class="auth-inner m-0">
          <b-col
              lg="3"
              class="text-center flex-column d-flex align-items-center auth-bg justify-content-center px-1 p-lg-5"
          >
            <p class="header-bottom">Սքանավորեք հաշվառման փաստաթուղթը կամ տեխզննման արատորոշման քարտը</p>
            <b-img
                @click="openScanner"
                fluid
                class="scanner-image"
                :src="qrUrl"
                alt="Login V2"
            />

          </b-col>
        </b-row>
        <div v-if="!$device.isMobile" class="login-footer" v-show="showGov">
          <div class="gov-footer">
            <div class="logo">
              <img :src="require('@/assets/images/logo/arm.png')"/>
            </div>
            <div class="name">
              <strong>ՀՀ տարածքային կառավարման</strong>
              <span>եվ ենթակառուցվածքների նախարարություն</span>
            </div>
          </div>
<!--          <div class="social-footer">-->
<!--            <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Finspect.am&width=200&layout=button_count&action=like&size=small&share=true&height=20&appId=2361660700805530" width="200" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>-->
<!--          </div>-->
        </div>
      </template>

      <div style="text-align: center; width: 100%;color: #000; font-weight: bold; font-size: 14px; position: fixed; bottom: 30px;" class="footer">© {{ new Date().getFullYear()}} Բոլոր իրավունքները պաշտպանված են</div>

    </template>

    <template v-else>
      <div class="mobile-header">
        <Menus />
        <div v-if="activeLayout==='search'" @click="activeLayout = 'login'" class="login">
          <fa-icon icon="sign-in-alt"/>
          <span>Մուտք</span>
        </div>
        <div v-else @click="activeLayout = 'search'" class="login">
          <fa-icon icon="search"/>
          <span>Որոնել</span>
        </div>
      </div>
      <b-row class="auth-inner m-0">

        <!-- Left Text-->
        <b-col
            lg="6"
            md="6"
            class="d-none d-lg-flex align-items-center justify-content-center p-5"
        >
          <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
            <b-img
                fluid
                :src="imgUrl"
                alt="Login V2"
            />
            <b-card-title title-tag="h2" class="mb-1 mt-2 text-center pb-1 site-title">Տրանսպորտային միջոցների տեխնիկական զննության միասնական շտեմարան</b-card-title>
            <template v-if="!$device.isMobile">
              <div class="gov-info">
                <span style="color: #000; font-weight: bold; font-size: 16px; margin-bottom: 20px;" class="gov-title">Համակարգից օգտվող պետական կառույցներ</span>
                <b-button @click="$bvModal.show('modal-gov-list')" style="min-width: 75%;background: #166136 !important; border-color: #166136 !important;" variant="success">Տեսնել ավելին</b-button>
              </div>
              <div style="color: #000; font-weight: bold; font-size: 14px; position: fixed; bottom: 30px;" class="footer">© {{ new Date().getFullYear()}} Բոլոր իրավունքները պաշտպանված են</div>
            </template>
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
            v-if="activeLayout === 'search'"
            lg="6"
            class="d-flex searchLayout auth-bg"
        >
          <b-col
              sm="12"
              md="12"
              lg="12"
              class="px-xl-2 mx-auto"
          >

            <div class="searchFormContent">
              <b-card-title title-tag="h3" class="font-weight-bold mb-1 text-center pb-1 login-header-title">
                Տեխզննման ժամկետի ստուգում            <!-- danger -->
<!--                <b-button-->
<!--                    class="tooltipButton"-->
<!--                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"-->
<!--                    v-b-tooltip.hover.v-dark-->
<!--                    title="Տեղեկատվությունը ստուգվում է վերջին երեք տարվա ժամանակահատվածի համար։"-->
<!--                    variant="outline-dark">-->
<!--                  <fa-icon icon="info-circle"/>-->
<!--                </b-button>-->
              </b-card-title>

              <!-- form -->
              <validation-observer #default="{invalid}" v-if="!verificationPage" ref="searchValidation">
                <b-form
                    autocomplete="off"
                    class="auth-login-form mt-2"
                    @submit.prevent
                >

                  <!-- document Number -->
                  <validation-provider
                      #default="{ errors }"
                      name="CarRegNo"
                      rules="required"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge">
                        <input
                            v-model="CarRegNo"
                            class="text-uppercase form-control"
                            autocomplete="off"
                            id="CarRegNo"
                            name="car-reg-no"
                            placeholder="Հաշվառման համարանիշը"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                      #default="{ errors }"
                      name="documentNumber"
                      rules="required|min:8"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge">
<!--                        <div @click="openSearchTypeModal" class="left-icon">-->
<!--                          <fa-icon icon="undo" />-->
<!--                        </div>-->
                        <input
                            v-model="documentNumber"
                            class="text-uppercase form-control"
                            autocomplete="off"
                            id="document_number"
                            name="document-number"
                            :placeholder="search_type_title"
                            v-mask="searchMask"
                        />
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                  <!-- submit buttons -->

                  <div class="w-100 mb-2 mobile-recaptcha">
                    <vue-recaptcha ref="d_recaptcha" sitekey="6LfeVXsfAAAAAP7sjbSql3RhvxicKaPn2d7LnvdE" language="hy" @verify="verifyRecaptcha($event)" @error="recaptchaStatus = false" />
                  </div>

                  <b-button
                      :disabled="invalid || !recaptchaStatus"
                      type="submit"
                      variant="success"
                      block
                      @click="checkTechService"
                  >
                    Որոնել
                  </b-button>
                </b-form>
              </validation-observer>
            </div>


            <div style="    min-width: 650px;" id="searchResult" v-if="searchResult">
              <b-card-title title-tag="h3"
                            class="font-weight-bold mb-1 text-center pb-1 login-header-title"></b-card-title>
              <div class="res">
                <div class="column">
                  <div class="cell">Մակնիշ</div>
<!--                  <div v-if="!paymentStatus" class="cell">Վերջին տեխզննում</div>-->
                  <div class="cell">Ժամկետի ավարտ</div>
                  <div class="cell">Վճարումներ</div>
                  <div v-if="paymentStatus  && check_is_expired(searchResult.lastDay)" class="cell">Ժամկետի ավարտ</div>
                </div>
                <div class="column">
                  <div class="cell">{{ searchResult.car }}</div>
<!--                  <div class="cell">{{ searchResult.CreatedDate }}</div>-->
                  <div class="cell">
                    <template v-if="check_is_expired(searchResult.lastDay)">
                      {{ searchResult.ValidDate }}
<!--                      <b-button-->
<!--                          style="left: 10px; top: 0"-->
<!--                          class="tooltipButton2"-->
<!--                          v-ripple.400="'rgba(30, 30, 30, 0.15)'"-->
<!--                          v-b-tooltip.hover.v-dark-->
<!--                          title="Տրանսպորտային միջոցը տեխնիկական զննության պետք է ներկայացվի մինչև նախորդ տեխնիկական զննությամբ տրամադրված արատորոշման քարտում նշված ամսվա վերջին օրը:"-->
<!--                          variant="outline-dark">-->
<!--                        <fa-icon icon="info-circle"/>-->
<!--                      </b-button>-->
                    </template>

                    <p class="isExpired animate-flicker" v-if="!check_is_expired(searchResult.lastDay)">Ժամկետանց</p>
                  </div>
                  <div class="cell">
                    <b-button style="width: 65%" @click="openOnePayPayments" variant="success" class="pay-ts">
                      <fa-icon icon="info-circle"/>
                    </b-button>
                  </div>
                  <div v-if="paymentStatus  && check_is_expired(searchResult.lastDay)" class="cell">
                    <b-button @click="$bvModal.show('payment-modal')" style="min-width: 75%; background: rgb(22, 97, 54) !important; border-color: rgb(22, 97, 54) !important;" variant="success">
                      <fa-icon style="    margin-right: 5px;"  icon="bell"/>
                      SMS ծանուցում
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div id="searchErrorMessage" v-if="searchMessage !== ''">
              <span>{{ searchMessage }}</span>
<!--              <b-button-->
<!--                  class="tooltipButton2"-->
<!--                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"-->
<!--                  v-b-tooltip.hover.v-dark-->
<!--                  title="Արդյունքների բացակայության դեպքում առաջարկում ենք որոնել VIN համարով"-->
<!--                  variant="outline-dark">-->
<!--                <fa-icon class="searchInfoIcon  animate-flicker" icon="info-circle"/>-->
<!--              </b-button>-->
            </div>
<!--            <div id="informMessage" v-if="searchMessage !== ''">-->
<!--              <b-button-->
<!--                  type="submit"-->
<!--                  variant="success"-->
<!--                  block-->
<!--                  @click="openOnePay"-->
<!--              >-->
<!--                Առցանց վճարումներ-->
<!--              </b-button>-->
<!--            </div>-->
          </b-col>
        </b-col>
        <b-col
            v-if="activeLayout === 'login'"
            lg="4"
            class="d-flex formLayout align-items-center auth-bg"
        >
          <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
          >
            <b-card-title title-tag="h3" class="font-weight-bold mb-1 text-center pb-1 login-header-title">Մուտք
              համակարգ
            </b-card-title>

            <!-- form -->
            <validation-observer v-if="!verificationPage" ref="loginValidation">
              <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
              >
                <!-- email -->
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                >
                  <b-form-group>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          autocomplete="off"
                          id="login-email"
                          v-model="userEmail"
                          name="login-email"
                          placeholder="Էլ․ փոստ"
                          :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                >
                  <b-form-group>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          autocomplete="off"
                          id="login-password"
                          v-model="password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Գաղտնաբառ"
                          :state="errors.length > 0 ? false:null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>


                <!-- submit buttons -->
                <b-button
                    type="submit"
                    variant="success"
                    block
                    @click="validationForm"
                >
                  Մուտք
                </b-button>
              </b-form>
            </validation-observer>

            <template v-else>
              <validation-observer ref="verificationValidation">
                <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent
                >
                  <p>{{ verificationMessage }}</p>

                  <!-- email -->
                  <validation-provider #default="{ errors }" rules="required|min:6">

                    <MazInput
                        type="text"
                        name="email"
                        v-model="verificationCode"
                        placeholder="Հաստատման կոդ"
                        autocomplete="new-email"
                        class="w-full mb-1"
                        v-mask="'######'"
                        old-left-icon-name="format_list_numbered"
                    />
                  </validation-provider>

                  <!-- submit buttons -->
                  <b-button
                      type="submit"
                      variant="success"
                      block
                      :disabled="checkVerifyValidation"
                      @click="verify"
                  >
                    Հաստատել
                  </b-button>
                </b-form>
              </validation-observer>
            </template>

          </b-col>
        </b-col>

        <!-- /Login-->
      </b-row>
    </template>

    <b-modal
        id="modal-notification"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Ժամկետի ավարտին մոտ ստանալ ծանուցում"
        :is-valid="false"
        @ok="confirmNotificationForm"
        :ok-disabled="validateNotificationForm"
    >
      <b-form @submit.prevent>
        <b-form-group>
          <b-form-input
              autocomplete="off"
              v-model="notificationForm.email"
              id="email"
              type="text"
              placeholder="Էլ․ փոստ"
          />
        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">

        <b-button :disabled="validateNotificationForm" @click="confirmNotificationForm" class="subscriptButton"
                  variant="info">
          Հաստատել
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="payment-modal"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered
        size="md"
        title=""
        :is-valid="false"
        @ok="confirmNotificationForm"
        :ok-disabled="validateNotificationForm"
    >
      <b-form @submit.prevent>
        <div class="paymentForm" id="paymentFormDesktop">
          <div class="formHeader">Տեխզննման ժամկետի ավարտին մոտ ստանալ SMS և էլեկտրոնային ծանուցում</div>
          <div class="paymentFormBody">
            <div class="formItems">
              <validation-observer      #default="{invalid}" ref="verificationValidation">
                <b-form
                    autocomplete="off"
                    class="auth-login-form mt-2"
                    @submit.prevent
                >

                  <!-- email -->
                  <validation-provider #default="{ errors }" rules="required|email">

                    <MazInput
                        type="text"
                        name="email"
                        v-model="paymentForm.email"
                        placeholder="Էլ. փոստ"
                        autocomplete="new-email"
                        class="w-full mb-1"
                        left-icon-name="email"
                    />
                  </validation-provider>

                  <validation-provider #default="{ errors }" rules="required|min:6">
                    <MazPhoneNumberInput
                        id="phone"
                        v-model="paymentForm.phone"
                        autocomplete="new-phone-op"
                        name="phone"
                        :only-countries="['AM']"
                        :default-country-code="'AM'"
                        :no-country-selector="true"
                        :translations="phoneTranslations"
                        left-icon-name="phone"
                        @update="phoneData = $event"
                    />
                  </validation-provider>

                  <div class="paymentMethods">
                    <label style="margin-left: 0;" for="paymentMethodCard" class="paymentMethod">
                      <input type="radio" id="paymentMethodCard" name="paymentMethod" v-model="paymentForm.method" value="card" />
                      <img :src="require('@/assets/images/logo/card.png')" />
                    </label>
                    <label for="paymentMethodIdram" class="paymentMethod">
                      <input type="radio" id="paymentMethodIdram" name="paymentMethod" v-model="paymentForm.method" value="idram" />
                      <img :src="require('@/assets/images/logo/idram.png')" />
                    </label>
                    <label style="margin-right: 0;" for="paymentMethodTelcell" class="paymentMethod">
                      <input type="radio" id="paymentMethodTelcell" name="paymentMethod" v-model="paymentForm.method" value="telcell" />
                      <img :src="require('@/assets/images/logo/telcell.png')" />
                    </label>
                  </div>

                  <template v-if="paymentForm.method === 'telcell'">
                    <span style="border: 2px dashed #e5464b;padding: .2rem 0; font-weight: 700; border-radius: 3px; background-color: #fff; color: #000!important;width: 90%; display: inline-block;" class="px-2">Խնդրում ենք մուտքագրել Telcell Wallet հավելվածում գրանցված Ձեր հեռախոսահամարը։</span>
                  </template>

                  <!-- submit buttons -->

                  <p class="paymentAmountInfo">Ծառայության արժեք՝ 100 դրամ</p>

                  <b-button
                      style="max-width: 220px; margin: 0 auto; background: rgb(22, 97, 54) !important; border-color: rgb(22, 97, 54) !important;"
                      type="submit"
                      variant="success"
                      block
                      :disabled="validateForm(invalid)"
                      @click="makePayment"
                  >
                    Հաստատել
                  </b-button>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        &nbsp;
      </template>
    </b-modal>
    <b-modal
        id="modal-gov-list"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered

        title=""
        :is-valid="false"
        @ok="confirmNotificationForm"
        :ok-disabled="validateNotificationForm"
    >

      <ul class="gov-list">
        <li>ՀՀ տարածքային կառավարման և ենթակառուցվածքների նախարարություն</li>
        <li>ՀՀ ոստիկանության «Պարեկային ծառայություն»</li>
        <li>ՀՀ ՔՏՀԱ տեսչական մարմին</li>
        <li>«Նորք» սոցիալական ծառայությունների տեխնոլոգիական և իրազեկման կենտրոն» հիմնադրամ</li>
      </ul>


      <template #modal-footer="{ ok, cancel, hide }">

        <b-button style="display: none" class="subscriptButton"
                  variant="info">
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="modal-search-type"
        cancel-variant="secondary"
        ok-only
        ok-title="Փոփոխել"
        centered
        size="sm"
        title="Որոնել ըստ"
    >
      <b-card-text>
        <div class="search-types-list">
          <div class="type" @click="changeSearchType('DocumentNumber')">ՏՄ Վկայագրի համարի</div>
          <div class="type" @click="changeSearchType('VIN')">Նույնացման համարի (VIN)</div>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
        id="modal-payment-info"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered
        size="md"
        title=""
        :is-valid="false"
        @ok="confirmNotificationForm"
        :ok-disabled="validateNotificationForm"
    >
      <b-form v-if="searchResult" @submit.prevent>
        <div class="payment-info">
          <div class="car">{{ searchResult.car }}</div>
          <div v-if="payments" class="payments">
            <div v-for="(payment, type) in payments" class="payment">
              <div v-if="payment.name" class="payment-title">
                <span>{{ payment.name }}</span>
              </div>
              <div class="payment-items">
                <div v-for="(item, index) in payment.info" class="item" :class="(payment.info.length === 3 && index === 1)? '' : 'withDashes'">
                  <div class="title">{{ item.name }}</div>
                  <div class="amount">{{ item.amount > 0 ? `${item.amount} դր.` : '-' }} </div>
                </div>
                <div class="paymentAction">
                  <b-button :disabled="totalPaymentItem(payment.info) === 0" @click="payPayment(type)" variant="success" class="pay-ts">
                    Վճարել
                  </b-button>
                  <b-button
                      v-if="type === 'ts'"
                      style="left: 10px; top: 0;min-width: initial; background: none!important;"
                      class="tooltipButton2"
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      v-b-tooltip.hover.v-dark
                      title="Ենթակա է վճարման հերթական տեխզննման ժամանակ կամ դրանից առաջ։"
                      variant="outline-dark">
                    <fa-icon style="font-size: 23px; margin-left: 10px;" icon="info-circle"/>
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="vehicleTypes">
              <div v-for="type in vehicleTypes" class="vehicleType">
                <div @click="selectVehicleType(type)" class="type">
                  {{ type.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        &nbsp;
      </template>
    </b-modal>

    <div v-if="showMobileRedirectAd" class="mobileRedirectAddHeaders">
      <div class="countDown" :class="showCountdown > 0 ? 'backgroundHas' : ''"><span v-if="showCountdown > 0">{{ showCountdown }}</span></div>
      <div v-if="showCountdown === 0" @click="showMobileRedirectAd = false" class="closeButton">x</div>
    </div>

    <div @click="openAutoZone" v-if="showMobileRedirectAd" class="mobileRedirectAdd">
      <div class="adContent">
        <div class="title">Տեխզննման ժամկետը Ձեր բջջայինում</div>
        <div class="description">Ներբեռնեք հավելվածը և տեխզննման ժամկետի ավարտին մոտ ստացեք ծանուցում։</div>
        <div class="logos">
          <div class="logo"><img :src="androidLogo" /></div>
          <div class="logo"><img :src="appleLogo" /></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Menus from './../../../layouts/components/Menus'
import axios from "axios";
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import moduleAuth from '@/store/auth/moduleAuth'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Scanner from '@/views/pages/auth/Scanner'
import firebase from '@core/firebase/firebase'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: {
    Scanner,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Menus,
    VueRecaptcha
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  created() {
    // Register module
    if (!store.hasModule(this.AUTH_STORE_MODULE_NAME)) store.registerModule(this.AUTH_STORE_MODULE_NAME, moduleAuth)
    if (this.$route.query && this.$route.query.action && this.$route.query.action === 'confirmArca' && this.$route.query.orderId) {
      this.checkPayment(this.$route.query.orderId);
    }

    if (this.$route.query && this.$route.query.idram) {
      this.alertIdram(this.$route.query.idram)
    }

    // Check Redirect
    if (this.redirectStatus === true) {
      this.initMobileAdd()
    }
  },

  data() {
    return {
      showMobileRedirectAd: false,
      showCountdown: 0,
      searchType: 'DocumentNumber',
      showGov: true,
      AUTH_STORE_MODULE_NAME: 'auth',
      status: '',
      password: '',
      userEmail: '',
      search: '',
      CarRegNo: '',
      documentNumber: '',
      vehicleType: '',
      sideImg: require('@/assets/images/pages/login/login.png'),
      bellImage: require('@/assets/images/icons/bell.png'),
      androidLogo: require('@/assets/images/logo/google.png'),
      appleLogo: require('@/assets/images/logo/apple.png'),
      required,
      email,
      scannerUrl: require('@/assets/images/pages/login/scanner.png'),
      qrUrl: require('@/assets/images/logo/iQR.png'),
      verificationCode: '',
      scanner: false,
      loginLayout: false,
      activeLayout: 'search',
      searchResult: null,
      payments: null,
      vehicleTypes: [],
      searchMessage: '',
      selectedLayout: 'search',
      notificationForm: {
        email: ''
      },
      paymentForm: {
        email: null,
        phone: null,
        method: null,
      },
      phoneData: {
        isValid: false,
      },
      phoneTranslations: {
        countrySelectorLabel: 'Կոդ',
        countrySelectorError: 'Ընտրեք երկիրը',
        phoneNumberLabel: 'Հեռախոսահամար',
        example: 'Օրինակ:',
      },
      captchaVerified: false,
      phone_number: null,
      codeResult: null,
      mobileRecaptchaStatus: false,
      recaptchaStatus: false,
      recaptchaCode: null
    }
  },
  computed: {

    totalPayments() {
      return payments => {
        let amount = 0;
        for (let key in payments) {
          let items = payments[key].info
          items.map(item => {
            amount += item.amount
          })
        }
        return amount
      }
    },

    totalPaymentItem() {
      return payments => {
        let amount = 0;
        payments.map(item => {
          amount += item.amount
        })
        return amount
      }
    },

    validateForm() {
      return invalid => (!!((invalid || !this.phoneData.isValid || !this.paymentForm.method)))
    },

    paymentStatus() {
      return this.$store.state.paymentStatus && this.$device.isMobile
    },

    redirectStatus() {
      return this.$store.state.redirectStatus && this.$device.isMobile
    },

    search_type_title() {
      return (this.searchType === 'DocumentNumber') ? 'ՏՄ վկայագրի համարը' : 'VIN համարը'
    },

    searchMask() {
      return (this.searchType === 'DocumentNumber') ? [/[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] : [/[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/, /[a-zA-Z0-9-]/]
    },

    check_is_expired() {
      return (value) => {
        const last_day = new Date(value)
        const lastDay2 = new Date(last_day.getFullYear(), last_day.getMonth() + 1, 0);
        lastDay2.setHours(23, 59, 59, 59)
        const now = new Date(Date.now())
        return (lastDay2 > now)
      }
    },

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },

    verificationPage() {
      return this.$store.state.verificationPage
    },
    verificationMessage() {
      return this.$store.state.verificationMessage
    },
    checkVerifyValidation() {
      return this.verificationCode.length !== 6
    },
    validateNotificationForm() {
      return this.notificationForm.email === '' || !this.validateEmail(this.notificationForm.email)
    }
  },
  methods: {

    initMobileAdd() {
      this.showMobileAdd();
      // const infoForMobileAdd = localStorage.getItem('infoForMobileAdd');
      // if(infoForMobileAdd === null) {
      //   this.showMobileAdd();
      // }
      // else {
      //   const now = new Date()
      //   if (now.getTime() > infoForMobileAdd) {
      //     this.showMobileAdd()
      //   }
      // }
    },

    showMobileAdd() {
      const timestamp = new Date().getTime()
      const exp = timestamp + (60 * 60 * 24 * 1000)
      localStorage.setItem('infoForMobileAdd', exp)
      this.showMobileRedirectAd = true;
      this.showCountdown = 6;
      let timeout = setInterval(() => {
        this.showCountdown -= 1;
        if (this.showCountdown === 0) {
          clearInterval(timeout);
        }
      }, 1000);
    },

    selectVehicleType (type) {
      this.vehicleType = type.id
      this.checkTechService()
    },

    payPayment (type) {
      if (type === 'ts') {
        this.openOnePay();
      } else if (type === 'tax') {
        let code = this.payments.tax.token
        this.getOnlyTokenFromVXSoft(code)
      }
    },

    getOnlyTokenFromVXSoft(vehicleCode) {
      this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/getTaxPaymentUrl', vehicleCode)
        .then(response => {
          window.open(`https://pay.e-community.am/am/sessions/${response.data.url}`, '_blank');
        })
        .catch(() => {
          window.open(`https://pay.e-community.am/am/tax-search`, '_blank');
        })
    },

    checkPayment (orderId) {
      const query = Object.assign({}, this.$route.query);
      delete query.orderId;
      this.$router.replace({ query });
      this.$store.dispatch('checkArcaPaymentStatus', orderId)
        .then(response => {
          this.$swal({
            title: 'Վճարումը հաստատված է, շուտով կստանաք ծանուցում։',
            text: '',
            icon: 'success',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Վճարման խափանում։',
            text: '',
            icon: 'error',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    alertIdram(type) {
      const query = Object.assign({}, this.$route.query);
      delete query.idram;
      this.$router.replace({ query });
      let text = type === 'success' ? 'Վճարումը հաստատված է, շուտով կստանաք ծանուցում։' : 'Վճարման խափանում։'
      let icon = type === 'success' ? 'success' : 'error';
      this.$swal({
        title: text,
        text: '',
        icon: icon,
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    makePayment () {
      this.$vs.loading()
      this.$store.dispatch('makePayment', {
        id: this.searchResult.id,
        ...this.paymentForm
      })
        .then(response => {
          let data = response.data;
          if (data.returnType === 'redirect') {
            window.location.href = data.formUrl;
          } else if (data.returnType === 'sendRequest') {
            let form = document.createElement("form");

            form.method = data.formData.method;
            form.action = data.formData.href;

            Object.keys(data.formData.data).forEach((key) => {
              let element = document.createElement("input")
              element.value = data.formData.data[key]
              element.name = key
              form.appendChild(element)
            })

            document.body.appendChild(form);
            form.submit();
          } else if (data.returnType === 'message') {
            this.$swal({
              title: data.message,
              text: '',
              icon: data.type,
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          this.$vs.loading.close()
        })
        .catch(error => {
          this.$swal({
            title: 'Վճարման խափանում։',
            text: '',
            icon: 'error',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$vs.loading.close()
        })
    },

    verifyRecaptcha(event) {
      this.recaptchaStatus = true
      this.recaptchaCode = event
    },

    verifyRecaptchaMobile(event) {
      this.mobileRecaptchaStatus = true
      this.recaptchaCode = event
    },

    openOnePay() {
      window.open('https://onepay.am', '_blank');
    },

    openOnePayPayments() {
      this.$bvModal.show('modal-payment-info')
    },

    openAutoZone() {
      let os = this.getMobileOperatingSystem()
      if (os === 'Android') {
        window.open('https://play.google.com/store/apps/details?id=com.autozone13', '_blank')
      } else {
        window.open('https://apps.apple.com/us/app/autozone/id6474038080', '_blank')
      }
    },

    doNothing() {
      let os = this.getMobileOperatingSystem()
      console.log('os',os)
    },

    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    validationForm() {
      const payload = {
        email: this.userEmail,
        password: this.password
      }
      this.$refs.loginValidation.validate()
          .then(success => {
            if (success) {
              this.$vs.loading()
              const payload = {
                email: this.userEmail,
                password: this.password
              }
              this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/signInUser', payload)
                  .then(response => {
                    if (response.statusCode === 203) {
                      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                          'recaptcha-container-auth', {
                            'size': 'normal',
                            hl: 'hy',
                            callback: () => {
                              this.sendVerification()
                            },
                          },
                      )
                      this.recaptchaVerifier.render()
                      this.phone_number = response.phone
                    }
                    this.$vs.loading.close()
                  })
                  .catch((error) => {
                    this.$vs.loading.close()
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },

    sendVerification() {
      this.$vs.loading()
      const number = this.phone_number
      firebase.auth()
          .signInWithPhoneNumber(number, this.recaptchaVerifier)
          .then(confirmationResult => {
            this.captchaVerified = true
            this.errorMessage = ''
            this.$vs.loading.close()
            this.codeResult = confirmationResult
            this.phoneAction = 'verifyCode'
            this.header_text = ''
            this.askPhoneAgain = false
          })
          .catch(error => {
            if (error.code === 'auth/invalid-phone-number') {
              this.phoneAction = 'inputPhone'
            }
            this.$vs.loading.close()
          })
    },

    verify() {
      this.$refs.verificationValidation.validate()
          .then(success => {
            if (success) {
              this.$vs.loading()
              const payload = {
                email: this.userEmail,
                password: this.password,
                verificationCode: this.verificationCode,
              }
              this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/userVerification', payload)
                  .then(() => {
                    this.$vs.loading.close()
                  })
                  .catch((error) => {
                    this.$vs.loading.close()
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },

    openScanner() {
      this.scanner = true
    },

    onlyEnNumber(e) {
      if (this.searchType === 'CarRegNo') {
        if (!(/^[0-9a-zA-Z/bB\b\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
          e.preventDefault()
        }
        else {
          if (!(/^[0-9a-zA-Z/bB\-\b\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
            e.preventDefault()
          }
        }
      }

    },

    onlyEnNumberMobile() {
      let value = this.search;
      if (this.searchType === 'CarRegNo') {
        value = value.replace(/[^0-9a-zA-Z]/gi, "");
      }
      else {
        value = value.replace(/[^0-9a-zA-Z\-]/gi, "");
      }

      this.search = value;
    },

    checkTechService() {
      this.searchResult = null
      this.payments = null
      this.vehicleTypes = []
      this.searchMessage = ''
      this.$refs.searchValidation.validate()
          .then(success => {
            if (success) {
              this.$vs.loading()
              this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/checkTechService', {
                CarRegNo: this.CarRegNo,
                documentNumber: this.documentNumber,
                code: this.recaptchaCode,
                vehicleType: this.vehicleType
              })
                  .then(response => {
                    this.$vs.loading.close()
                    if (response.data.status) {
                      this.searchResult = response.data.data
                      this.payments = response.data.payments
                      this.vehicleTypes = response.data.vehicleTypes
                      this.vehicleType = null
                      let device = this.getMobileOperatingSystem()
                      if (device === 'Android' || device === 'iOS') {
                        setTimeout(() => {
                          this.$swal({
                            title: 'Ցանկանո՞ւմ եք ժամկետի ավարտին մոտ ստանալ ծանուցում։',
                            html: '',
                            imageUrl: this.bellImage,
                            imageWidth: 100,
                            imageHeight: 100,
                            showCancelButton: true,
                            confirmButtonText: 'Այո',
                            cancelButtonText: 'Ոչ',
                            customClass: {
                              confirmButton: 'btn confirmButtonBell',
                              cancelButton: 'btn btn-danger ml-1',
                            },
                            buttonsStyling: false,
                          })
                              .then(result => {
                                if (result.value) {
                                  this.openAutoZone()
                                }
                              })
                        }, 2000)
                      }
                    } else {
                      this.searchMessage = response.data.message
                    }
                    window.grecaptcha.reset();
                    this.recaptchaStatus = false;
                    this.mobileRecaptchaStatus = false;
                  })
                  .catch((error) => {
                    this.vehicleType = null
                    window.grecaptcha.reset();
                    this.recaptchaStatus = false;
                    this.mobileRecaptchaStatus = false;
                    this.$vs.loading.close()
                    this.$swal({
                      title: 'Ծառայությունը ժամանակավորապես անհասանելի է։',
                      text: '',
                      icon: 'error',
                      showCloseButton: true,
                      showConfirmButton: false,
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
            }
          })

    },

    openSearchTypeModal () {
      this.$bvModal.show('modal-search-type')
    },

    changeSearchType (type) {
      this.searchType = type
      this.$bvModal.hide('modal-search-type')
    },

    confirmNotificationForm() {
      this.$vs.loading()
      const payload = {
        email: this.notificationForm.email,
        CarRegNo: this.searchResult.CarRegNo,
        ValidDate: this.searchResult.lastDay,
        checkup: this.searchResult.id
      }
      this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/addNotification', payload)
          .then(() => {
            this.notificationForm.email = ''
            this.$vs.loading.close()
            this.$bvModal.hide('modal-notification')
            this.$swal({
              title: 'Հայտն ընդունված է։',
              text: '',
              icon: 'success',
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.header-bottom {
  border-bottom: 2px solid #39a164;
  color: #000
}

.mobile-header {
  position: absolute;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;

  .login {
    cursor: pointer;
  }

  .scanner-image {
    height: 40px;
    width: auto;
    margin-top: 5px;
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 600;
    margin-top: 5px;
    border-bottom: 2px solid #39a164;
    padding: 5px;

    svg {
      margin-right: 0.5rem;
    }
  }
}

@media (max-width: 768px) {
  .scanner-image {
    width: 135px;
  }

  .swal2-title {
    font-size: 16px;
  }

  .mobile-header {
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;

    .scanner-image {
      height: 40px;
      width: auto;
      margin-top: 5px;
    }

    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-weight: 600;
      margin-top: 5px;
      border-bottom: 2px solid #39a164;
      padding: 5px;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

}

.closeScannerContainer {
  position: fixed;
  bottom: 20px;
  left: 5%;
  width: 90%;
  text-align: center;

  button {
    width: 50%;
    margin: 0 auto;
  }
}

.envelopeIcon {
  font-size: 22px;
  color: #166136;
  cursor: pointer;
}

#searchResult {
  margin-top: 2rem;

  .res {

    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 0.9rem;
    font-weight: bold;
    background: #fff;
    border: 1px solid #ccc;

    .column {
      display: flex;
      flex-flow: row;
      width: 100%;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
      }

      .cell {
        padding: 15px 5px;
        width: 33.3%;
        border-right: 1px solid #ccc;
        text-align: center;

        &:last-child {
          border-right: none;
        }
      }

      .label {
        width: 50%;
        border-right: 1px solid #ccc;
        padding: 10px 5px;
        text-align: center;
      }

      .value {

        width: 50%;
        padding: 10px 5px;
        text-align: center;
      }
    }
  }
}

#searchErrorMessage {
  width: 300px;
  margin: 15px auto;
  color: #f44336;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding: 7px;
}

.formLayout {
  padding: 1rem 6rem !important;
}

.tooltipButton2 {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
  top: -2px;
  font-size: 13px;
  left: 2px;
}
#informMessage {
  width: 300px;
  color: #2196f3;
  font-size: 13px;
  font-weight: bold;
  margin: 15px auto;
  text-align: center;
  padding: 7px;
  button {
    &.btn {
      background: #166136 !important;
      border-color: #166136 !important;
    }
  }
}
.mobileSearchLayout {

  .login-header-title {
    font-size: 15px !important;
  }

  #searchErrorMessage, #informMessage {
    width: 100%;
  }

  button {
    &.btn {
      background: #166136 !important;
      border-color: #166136 !important;
      height: 50px;
      font-weight: 500;
      font-size: 20px;

      &.tooltipButton {
        background: none !important;
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
        height: auto !important;

        svg {
          color: #3d4454;
          font-size: 16px;
          position: relative;
          top: -2px;
          left: 7px;
        }
      }
    }

    &.tooltipButton2 {
      background: none !important;
      border: none !important;
      padding: 0 !important;
      margin: 0 !important;
      height: auto !important;
      font-size: 13px;
      left: 7px;
      position: relative;
      top: 0;
    }
  }

  input {
    &.form-control {
      height: 50px;
      text-align: center;
      color: #000;
      font-weight: bold;
      font-size: 20px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

.searchLayout {
  padding: 20% 13% 0 0 !important;

  .searchFormContent {
    width: 300px;
    margin: 0 auto;

    .login-header-title {
      font-size: 1.2rem;
      border-bottom: none;
    }

    button {
      &.btn {
        background: #166136 !important;
        border-color: #166136 !important;
        height: 50px;
        font-weight: 500;
        font-size: 20px;

        &.tooltipButton {
          background: none !important;
          border: none !important;
          padding: 0 !important;
          margin: 0 !important;
          height: auto !important;

          svg {
            color: #3d4454;
            font-size: 16px;
            position: relative;
            top: -2px;
            left: 7px;
          }
        }

      }

    }

    input {
      &.form-control {
        height: 50px;
        text-align: center;
        color: #000;
        font-weight: bold;
        font-size: 20px;

        &::placeholder {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1555px) {
  .formLayout {
    padding: 0rem 0rem !important;
  }
}

#modal-notification {
  .form-group {
    margin-bottom: 0;
  }

  .modal-header {
    background-color: #f6f6f6;

    .modal-title {
      color: #000;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;

    .subscriptButton {
      background: #166136 !important;
      border-color: #166136 !important;
      min-width: 140px;
      font-weight: bold;
      font-size: 16px;

      &:hover {
        box-shadow: 0 8px 25px -8px #166136;
      }
    }
  }
}

.swal2-close:focus {
  box-shadow: none !important;
}

[dir] .tooltip.b-tooltip-dark .tooltip-inner {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #ccc;
  max-width: 300px;
}

[dir] .tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before, [dir] .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #a8a8a8;
}

.isExpired {
  margin: 0 !important;
  color: #f44336;
  font-size: 12px;
}

.login-footer {
  position: absolute;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gov-footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  flex-flow: row;
  padding: 0 5px;

  .logo {
    width: 15%;

    img {
      width: 100%;
    }
  }

  .name {
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;

    span {
      font-size: 0.78rem;
      font-weight: 600;
      margin-top: 2px;
    }

    strong {
      font-size: 0.78rem;
    }
  }
}

.gov-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 420px;
  margin-top: 15px;

  .logo {
    width: 15%;

    img {
      width: 100%;
    }
  }
  .name {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #000;
  }
}

.inputGroup {
  position: relative;

  .inputScanner {
    position: relative;
    top: 0;
    width: 45px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8d6de;
    border-left: none;
  }

  .form-control {

  }

}
.searchInfoIcon {
  color: #3d4454;
  font-size: 16px;
  position: relative;
  top: 0;
  left: 7px;
}
.left-icon {
  width: 15%;
  background: #fff;
  border: 1px solid #d8d6de;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: #2196f3;
  }
}
[dir] .input-group:not(.bootstrap-touchspin):focus-within .form-control, [dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #d8d6de;
}
.search-types-list {
  display: flex;
  flex-direction: column;
  .type {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 5px 10px;
    color: #000;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
  }
}
#modal-search-type {
  .modal-footer {
    display: none
  }
}
#recaptcha-container-auth, #recaptcha-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-recaptcha > div > div {
  margin: 0 auto;
}
.pay-ts {
  height: 28px!important;
  font-size: 13px!important;
  font-weight: bold!important;
  padding: 7px 15px;
  border-radius: 6px;
  border-color: #196136 !important;
  background-color: #196136 !important;
}
ul {
  &.gov-list {
    color: #000;
    font-weight: bold;
    font-size: 13px;
    li {
      margin-bottom: 10px;
    }
  }
}
#modal-gov-list___BV_modal_footer_ {
  display: none;
}

#paymentFormDesktop {
  &.paymentForm {
    color: #000;
    font-weight: bold;
    background: #fff;
    //border: 1px solid #ccc;
    margin: 10px 0;
    padding: 10px 0;
    .formHeader {
      text-align: center;
      font-size: 15px;
      border-bottom: 3px solid;
      padding: 7px 0;
    }
    .paymentFormBody {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .title {
        margin: 10px 0;
        padding: 0;
      }
      .formItems {
        display: flex;
        width: 100%;
        flex-direction: column;
        .paymentMethods {
          display: flex;
          .paymentMethod {
            border: 1px solid #e4e4e4;
            border-radius: 3px;
            margin: 10px 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 10px;
            width: 33.3%;
            img {
              height: 25px;
            }
            input {
              margin: 0 8px;
            }
          }
        }
        .paymentAmountInfo {
          margin: 20px 0;
        }
      }
    }
  }
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.column {
  .label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#payment-modal {
  .modal-body {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: 0!important;
  }
.modal {
   padding-right: 0rem!important;
   padding-left: 0rem!important;
 }
}
#payment-modal {
  .modal-footer {
    display: none;
  }
}
.confirmButtonBell {
  background: #166136;
  color: #fff;
}
.payment-info {
  display: flex;
  flex-direction: column;
  .car {
    font-size: 18px;
    text-align: center;
    color: #223343;
    font-weight: bold;
    //border-bottom: 2px solid #223343;
    padding-bottom: 10px;
  }
  .vehicleTypes {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-flow: wrap;
    .vehicleType {
      width: 49%;
      border-radius: 4px;
      background: #f1f1f1;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .payments {
    display: flex;
    flex-direction: column;
    .total {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #223343;
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .payment {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .payment-title {
        color: #223343;
        font-weight: bold;
        font-size: 17px;
        text-align: center;
        margin: 10px 0;
      }
      .payment-items {
        background: #f6f6f6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .item {
          display: flex;
          justify-content: space-between;
          padding: 17px 20px;
          color: #223343;
          font-size: 15px;
          font-weight: bold;
          &.withDashes {
            border-bottom: 2px dashed #ccc;
          }
        }
      }
      .paymentAction {
        margin: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          min-width: 175px;
          border-radius: 20px;
          height: 35px !important;
          font-size: 14px !important;
          font-weight: bold !important;
          &:active {
            opacity: 1;
          }
        }
      }
    }
  }
}
.tooltipButton2 {
  position: relative;
  left: 2px;
}
#modal-payment-info {
  .modal-footer {
    display: none;
  }
  .modal-header {
    background: #fff;
    padding: 0 1.4rem;
    .close {
      position: relative;
      top: 6px;
      right: 1px;
    }
  }
  .modal-body {
    padding-top: 0;
  }
}
.mobileRedirectAddHeaders {
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  z-index: 999999;
  .countDown {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 15px;
    &.backgroundHas {
      background: rgb(204 204 204 / 53%);
    }
  }
  .closeButton {
    width: 30px;
    height: 30px;
    background: #ea5455;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
.mobileRedirectAdd {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .adContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #112c40;
    font-weight: bold;
    text-align: center;
    padding: 0 30px;
    .title {
      font-size: 22px;
      margin: 15px 0;
    }
    .description {
      font-size: 15px;
      margin: 25px 0;
    }
    .logos {
      .logo {
        margin: 10px 0;
        img {
          width: 200px;
        }
      }
    }
  }
}
</style>

