<template>
  <b-row id="scanner" class="auth-inner m-0">
    <template v-if="text !== ''">
      <template v-if="scanError">
        <div class="mobile-header">
          <div @click="clearScanResult" class="login">
            <fa-icon icon="qrcode"/>
            <span>Սքանավորել</span>
          </div>
        </div>
        <b-col
            lg="3"
            class="d-flex align-items-center auth-bg px-0 whiteBackground p-lg-5"
        >
          <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
          >
            <b-card-title title-tag="h3" class="header-bottom font-weight-bold mb-1 text-center pb-1 login-header-title">
              Ստուգման արդյունք
            </b-card-title>

            <b-card-body class="scan-result">
              <b-alert
                  variant="danger"
                  show
              >
                <div class="alert-body">
                  <span>Շտեմարանում {{ this.text.toUpperCase() }}-ի վերաբերյալ տեղեկատվություն առկա չէ։</span>
                </div>
              </b-alert>
            </b-card-body>


          </b-col>
        </b-col>
      </template>
      <template v-else-if="scan_result">
        <div class="mobile-header">
          <div @click="clearScanResult" class="login">
            <fa-icon icon="qrcode"/>
            <span>Սքանավորել</span>
          </div>
        </div>
        <b-col
            lg="3"
            class="d-flex align-items-center auth-bg px-0 whiteBackground p-lg-5"
        >
          <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
          >
            <b-card-title title-tag="h3" class="header-bottom font-weight-bold mb-1 text-center pb-1 login-header-title">
              Ստուգման արդյունք
            </b-card-title>

            <b-card-body class="scan-result">
              <div class="results">
                <div class="result">
                  <div class="title">
                    <fa-icon icon="tools"/>
                    <span>Տեխզննում</span>
                  </div>
                  <div class="value">{{ scan_result.validDate }}</div>
                </div>
                <div class="result">
                  <div class="title">
                    <fa-icon icon="car-crash"/>
                    <span>ԱՊՊԱ</span>
                  </div>
                  <div class="value">-</div>
                </div>
                <div class="result">
                  <div class="title">
                    <fa-icon icon="burn"/>
                    <span>Գազաբալոն</span></div>
                  <div class="value">-</div>
                </div>
              </div>
            </b-card-body>


          </b-col>
        </b-col>
      </template>
    </template>
    <template v-else>
      <div class="mobile-header">
        <div class="">
        </div>
        <div @click="openLoginForm" class="login">
          <fa-icon icon="search"/>
          <span>Որոնել</span>
        </div>
      </div>
      <b-col
          lg="3"
          class="d-flex align-items-center auth-bg px-0 whiteBackground p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)"
                               @loaded="() => onLoaded()"
          ></StreamBarcodeReader>

        </b-col>
      </b-col>

    </template>
  </b-row>
</template>

<script>

import { StreamBarcodeReader } from 'vue-barcode-reader'
import {
  BRow, BCol, BCardTitle, BCardBody, BAlert
} from 'bootstrap-vue'

export default {
  name: 'scanner',
  components: {
    StreamBarcodeReader,
    BRow,
    BCol,
    BCardTitle,
    BCardBody,
    BAlert
  },
  data() {
    return {
      text: '',
      id: null,
    }
  },
  computed: {
    scan_result() {
      return this.$store.state.scan_result
    },
    scanError() {
      return this.$store.state.scanError
    }
  },
  methods: {
    onDecode(a, b, c) {
      this.text = a
      if (this.id) clearTimeout(this.id)
      this.id = setTimeout(() => {
        if (this.text === a) {
          if (this.text !== '') {
            this.gotCode(this.text)
          }
        }
      }, 1500)
    },
    onLoaded() {
      console.log('load')
    },

    gotCode() {
      this.$vs.loading()
      const audioFile = require('@/assets/audio/beep.mp3')
      const audio = new Audio(audioFile)
      audio.play()
      this.$store.dispatch('getCheckupData', this.text)
      .then(() => {
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    },

    openLoginForm() {
      this.$parent.scanner = false
      this.$parent.loginLayout = true
    },

    clearScanResult() {
      this.text = ''
      this.$store.commit('CLEAR_SCAN_RESULT')
    }
  },
}
</script>

<style lang="scss">
.whiteBackground {
  background: #fff !important;
}

#scanner {
  height: 70vh;

  .scanner-container {
    margin-top: 2.7rem;

    //video {
    //  height: 90%;
    //}
  }
}

.scan-result {

  .results {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #000;
    border: 1px solid #eeeeee;

    .result {
      display: flex;
      width: 100%;
      padding: 0 5px;
      font-weight: bold;
      min-height: 30px;
      align-items: center;
      border-bottom: 1px solid #eeeeee;

      &:last-child {
        border-bottom: none;
      }

      .title {
        width: 50%;
        border-right: 1px solid #eeeeee;

        svg {
          margin: 0 5px;
        }
      }

      .value {

        width: 50%;
        text-align: center;
      }
    }
  }
}
</style>
